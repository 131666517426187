import { get, post } from "@/utils/axios";

export function scaLogin(testLoginCode) {
  return post("/scalogin/ScaLogin", JSON.stringify(testLoginCode));
}

export function scaAutoLogin(autoLoginCode) {
  return post("/scalogin/ScaAutoLogin", JSON.stringify(autoLoginCode));
}

export function scaUserLogin(userName, password) {
  return post("/scalogin/ScaUserLogin", {
    userName,
    password: password || null
  });
}

export function fetchIncompleteTestList({ regionGuid, nodeGuid, personGuid }) {
  return post("/scalbgroup/GetIncompleteTest", {
    customerGuid: regionGuid,
    manageNodeGuid: nodeGuid,
    personGuid
  });
}

export function fetchLbGroupStatusList(testPersonGuid) {
  return post("/scalbgroup/GetLbGroupListWithStatus", { guid: testPersonGuid });
}

export function fetchOpenLbGroupStatus(lbGroupGuid, testPersonGuid) {
  return post(`/scalbgroup/GetOpenLbGroupWithStatus/${lbGroupGuid}`, {
    guid: testPersonGuid
  });
}

export function fetchLbDetailsFromGuid(lbGuid) {
  return get(`/scalb/LbScaInfoFromGuid/${lbGuid}`);
}

export async function fetchAllQuesWithAnsFromLbGuid({
  regionGuid,
  nodeGuid,
  lbGuid,
  personGuid,
  lbGroupGuid
}) {
  let response = await post("/scalb/AllQuesListWithAnsFromLbGuid", {
    customerGuid: regionGuid,
    manageNodeGuid: nodeGuid,
    lbGuid,
    testPersonGuid: personGuid,
    lbGroupGuid
  });
  return {
    caseGuid: response.caseGuid,
    quesAnsList: response.quesAnsList,
    timeCost: response.timeCost,
    optionZeroKeyNum: response.optionZeroKeyNum,
    nextAnsStatus: mapToAnswerStatus(response.nextAnswerStatus)
  };
}

export async function searchPersonInfo(loginKey, password) {
  let response = await post("/scaperson/SearchTestPersonInfo", {
    loginKey,
    password: password || null
  });
  return response;
}

export function fetchPersonInfoInternal(personGuid) {
  return post("/scaperson/GetTestPersonInternal", { guid: personGuid });
}

export async function fetchIsShowScaReport(lbGroupGuid) {
  let response = await get("/scalbgroup/IsShowScaReport", { lbGroupGuid });
  return response.value;
}

export async function fetchIsContinuousTest(lbGroupGuid) {
  let response = await get("/scalbgroup/IsContinuousTest", { lbGroupGuid });
  return response.value;
}

export async function saveOneAnswerAndGetNext({
  caseGuid,
  index,
  answer,
  timeCost
}) {
  let response = await post("/scalb/SaveOneTestAnswerAndGetNext", {
    caseGuid,
    index,
    answer,
    timeCost
  });
  return mapToAnswerStatus(response);
}

export async function fetchNextAnswerStatus(caseGuid, quesIndex) {
  let response = await post("/scalb/GetNextAnswerStatus", {
    caseGuid,
    quesIndex
  });
  return mapToAnswerStatus(response);
}

export async function fetchPrevAnswerStatus(caseGuid, quesIndex) {
  let response = await post("/scalb/GetPrevAnswerStatus", {
    caseGuid,
    quesIndex
  });
  return mapToAnswerStatus(response);
}

export async function startOneTest({ caseGuid }) {
  let response = await post("/scalb/StartOneTest", {
    guid: caseGuid
  });
  return response.guid;
}

export async function completeOneTest({ caseGuid, timeCost }) {
  let response = await post("/scalb/CompleteOneTest", {
    caseGuid,
    timeCost
  });
  return response.guid;
}

export async function saveOneTest({ caseGuid, timeCost }) {
  let response = await post("/scalb/SaveOneTest", {
    caseGuid,
    timeCost
  });
  return response.guid;
}

export async function terminateOneTest({ caseGuid }) {
  let response = await post("/scalb/TerminateOneTest", {
    guid: caseGuid
  });
  return response.guid;
}

function mapToAnswerStatus(response) {
  return {
    nextTitleIndex: response.nextTitleIdx,
    nextQuesIndex: response.nextIdx,
    totalQuesCount: response.quesCnt,
    answeredQuesCount: response.ansQuesCnt,
    allowPrev: response.allowPrev,
    allowNext: response.allowNext,
    isShowProgress: response.showProg,
    timeLimitSec: response.timeLimSec && Number(response.timeLimSec),
    defaultAns: response.defAns
  };
}
