import { get, post } from "@/utils/axios.js";

export function initAndGetAllRoles() {
  return post("/customerinit/InitAndGetAllRoles");
}

export async function initNewUser({
  loginKey,
  loginPwd,
  displayName,
  roleName
}) {
  let response = await post("/customerinit/InitNewCustomer", {
    loginKey,
    loginPwd,
    displayName,
    roleName
  });
  let regionGuid = response.guid;
  return post("/customerinit/InitLbAndCategory", {
    customerGuid: regionGuid
  });
}

export async function userAuth(username, password) {
  let response = await post("/adminauth/AdminAuthenticate", {
    userName: username,
    password: password
  });
  return {
    regionGuid: response.customerGuid,
    loginNodeGuids: response.loginNodeGuids,
    userGuid: response.userGuid,
    userName: response.userName,
    role: response.role
  };
}

export async function userSignOut() {
  let response = await post("/adminauth/AdminSignOut");
  return response.value;
}

export function fetchSingletonCustInfo() {
  return get("/customer/SingletonCustomerInfo", {
    t: new Date().getTime()
  });
}

export function fetchUserCustInfo(regionGuid) {
  return get("/customer/CustomerInfoFromGuid", {
    t: new Date().getTime(),
    customerGuid: regionGuid
  });
}

export function saveUserCustInfo(
  regionGuid,
  { userName, userDesc, website, landingTitle }
) {
  return post("/customer/SaveCustomerInfo", {
    guid: regionGuid,
    displayName: userName,
    description: userDesc,
    website,
    landingTitle
  });
}

export async function uploadUserPortrait(regionGuid, imageFile) {
  if (!imageFile) {
    throw { message: "请点击上传图片" };
  }
  let formData = new FormData();
  formData.append("imageFile", imageFile, imageFile.name);
  let response = await post(
    `/customer/UploadCustomerPotrait/${regionGuid}`,
    formData
  );
  return response.guid;
}

export function fetchAllAdminUserList(regionGuid, nodeGuidList) {
  return post(`/user/AllAdminUserList/${regionGuid}`, nodeGuidList);
}

export async function createOneAdminUser({
  regionGuid,
  userName,
  loginKey,
  password,
  description,
  roleGuid,
  nodeGuidList
}) {
  let response = await post("/user/CreateOneAdminUser", {
    customerGuid: regionGuid,
    userName,
    loginKey,
    password,
    description,
    roleGuid,
    nodeGuidList
  });
  return response.guid;
}

export async function updateAdminUserInfo(
  userGuid,
  { regionGuid, userName, loginKey, description, roleGuid, nodeGuidList }
) {
  let response = await post(`/user/SaveAdminUserInfo/${userGuid}`, {
    customerGuid: regionGuid,
    userName,
    loginKey,
    description,
    roleGuid,
    nodeGuidList
  });
  return response.guid;
}

export async function changeOrCreateUserPwd(
  userGuid,
  oldPassword,
  newPassword
) {
  let response = await post(`/user/ChangeOrCreatePassword/${userGuid}`, {
    oldPassword,
    newPassword
  });
  return response.guid;
}

export async function deleteOneAdminUser(userGuid) {
  let response = await post("/user/DeleteOneAdminUser", {
    guid: userGuid
  });
  return response.guid;
}
