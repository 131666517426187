export const scaLoginMode = {
  user: {
    id: 0,
    value: "user",
    text: "用户"
  },
  testPwd: {
    id: 1,
    value: "testPwd",
    text: "测量码"
  }
};
